@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
  position: relative;
}
/* Firefox */
* {
  scrollbar-width:none;
  scrollbar-color: #2600ff #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #8e8e8e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #878787;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #8e8e8e;
}

.mobile-menu {
  font-size: 2.3rem;
  cursor: pointer;
  display: none;
}
.mobile-menu:hover {
  color: #147efb;
  transition: all 0.3s;
}
@media (max-width: 900px) {
  .mobile-menu {
    display: flex;
  }
}

.closed-menu {
  left: -100% !important;
}

.open-menu {
  left: 0 !important;
}

.mobile-nav {
  display: flex;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.mobile-nav span {
  font-size: 3.3rem;
  position: absolute;
  top: 2rem;
  right: 4rem;
  cursor: pointer;
}
.mobile-nav span:hover {
  color: #147efb;
  transition: all 0.3s;
}
.mobile-nav ul {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-size: 2.3rem;
  list-style: none;
}
.mobile-nav ul a {
  color: black;
  text-decoration: none;
  font-weight: 500;
}
.mobile-nav ul a:hover {
  color: #147efb;
  transition: all 0.3s;
}


.hero-img {
  background-image: url("./me.jpg");
  width: 35rem;
  height: 35rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 3px solid #2d2e32;
  animation: morph 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  position: relative;
  transition: all 1s ease-in-out;
}
@media (max-width: 500px) {
  .hero-img {
    width: 28rem;
    height: 28rem;
  }
}
@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}